@import "styles/index";

.self-service-root {
  @include baseStyle;

  cursor: default;

  font-family: $font-family;

  &.bilanz {
    @include bilanzStyle;
  }

  &.blick {
    @include blickStyle;
  }

  &.handelszeitung {
    @include handelszeitungStyle;
  }

  &.beobachter {
    @include beobachterStyle;
  }

  &.tele {
    @include teleStyle;
  }

  &.tv4 {
    @include tv4Style;
  }

  &.tvstar {
    @include tvstarStyle;
  }

  &.tv8 {
    @include tv8Style;
  }

  &.streaming {
    @include streamingStyle;
  }

  &.pme {
    @include pmeStyle;
  }

  &.landliebe {
    @include landliebeStyle;
  }

  &.glueckspost {
    @include glueckspostStyle;
  }

  &.schweizer-illustrierte {
    @include schweizer-illustrierteStyle;
  }

  &.illustre {
    @include illustreStyle;
  }

  &.caminada {
    @include caminadaStyle;
  }

  &.interview {
    @include interviewStyle;
  }
}

.content {
  min-height: 100vh;
  background-color: $color-background-primary;
  color: $color-font-primary;
  padding-left: px(173);
  padding-right: px(173);
  padding-top: px(64);

  .section-one {
    h1 {
      color: $color-font-primary;
      font-weight: 600;
      font-size: px(18);
      line-height: px(22);
      font-family: $font-family-secondary;
    }

    h2 {
      color: $color-font-primary;
      font-weight: 600;
      font-size: px(14);
      line-height: px(20);
    }

    h3 {
      color: $color-font-primary;
      font-weight: normal;
      font-size: px(26);
      line-height: px(20);
    }

    .context-text-header-two {
      width: 85%;
      margin-top: px(48);
      margin-bottom: px(24);
    }

    .context-text-header-three {
      width: 85%;
      margin-top: px(48);
      margin-bottom: px(24);
      font-size: 14px;
      line-height: 20px;
    }
  }

  .section-two {
    padding-bottom: 40px;
  }

  .hide-header & {
    min-height: 0px;
    padding-top: px(8);
  }
}

.form-complete-message {
  margin-left: 10%;
  margin-top: 5%;
}

.input-button-wrapper {
  margin-top: 50px;
}

input {
  height: 48px;
  border: none;
  border-bottom: 2px solid #DEE2E6;
  margin-top: 2px;
  margin-bottom: 48px;
  padding-left: 12px;
  color: black;
}

input:focus {
  outline-width: 0;
}

input[type=submit] {
  width: 200px;
  height: 48px;
  background-color: $color-button-primary;
  color: $color-button-font-primary;
  font-weight: bold;
  cursor: pointer;
}

.form-publication-code-radio-group-container {
  display: flex;

  .form-publication-code-radio-container {
    margin-right: px(16);
    cursor: pointer;
    input[type=radio] {
      height: auto;
      margin-bottom: 0;
    }
    & > * {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .content {
    flex-direction: column;
    padding-left: px(50);
    padding-right: px(50);
  }
}

@media only screen and (max-width: 400px) {
  .content {
    padding-left: px(20);
    padding-right: px(20);
  }
}
