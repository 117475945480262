.contact {
  display: flex;
  flex-direction: column;
  width: px(306);
  margin: px(74) px(25) px(15) px(15);
  padding: px(27) px(24) px(39) px(24);
  background-color: $color-background-third;

  h2 {
    font-size: px(14);
    color: $color-font-secondary;
  }

  p {
    font-size: px(14);
    color: $color-font-secondary;
    line-height: px(20);
    margin: px(12) 0 0;

    &.chat-hours {
      margin: 0 ;
    }
  }

  a {
    color: $color-font-secondary;
  }

  .contact-header-second {
    margin-top: px(32);
  }

  .contact-text-third {
    margin-top: px(24);
  }

  .contact-text-blick {
    color: $color-font-secondary;
    font-size: px(14);
    line-height: px(20);
  }
}

@media only screen and (max-width: 1200px) {
  .contact {
    margin: px(50) 0 0 0;
  }
}

@media only screen and (max-width: 600px) {
  .contact {
    width: 100%;
    padding: px(9) 0 px(19);

    h2 {
      margin-left: px(10);
    }

    p {
      margin-left: px(10);
    }
  }
}
