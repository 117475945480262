.header {
  height: $header-height;
  background-color: $color-background-secondary;
  color: $color-font-secondary;

  & .brandLogo {
    height: 100%;
    width: px(200);
    padding: px(10) px(20);
    background: $brand-logo no-repeat left;
    background-size: contain;
    background-origin: content-box;
    box-sizing: border-box;

    .caminada & {
      margin: auto;
      background-position: center;
    }
  }
}
