.category-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.sub-category-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-top: 50px;
  padding-top: 50px;
}

.category-wrapper {
  flex: 0 1 calc(32.5% - 10px);
  height: 74px;
  margin: 10px 12px 10px 0;
  border: 1px #d6d6d6 solid;
  border-radius: .25em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #FFFFFF;
  cursor: pointer;

  h4 {
    padding-left: 10px;
  }
}

.active {
  border: 1px solid #222222;
  color: #222222;
}

.category-wrapper:hover {
  border: 1px solid #222222;
  color: #222222;
}

.select {
  .select-header {
    margin-bottom: 5px;
    font-family: $font-family-secondary;
  }
}

.category-section-one, .category-section-two {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: white;
}

.category-section-two-wrapper {
  margin-top: 50px;
  padding-top: 50px;
  border-top: 1px solid gray;
}

.category-icon {
  margin-left: 20px;
}

@media only screen and (max-width: 600px) {
  .category-wrapper {
    flex: 0 1 calc(48% - 10px);
  }
}

@media only screen and (max-width: 700px) {
  .select {
    width: 100%;
    padding: 0;

    .select-header {
      padding-top: px(25);
      padding-left: px(10);
    }
  }

  .category-wrapper {
    flex: 0 1 calc(100%);
    margin-right: 0;
  }
}
