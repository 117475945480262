// BACKGROUNDS
$color-background-primary: xrgb(--color-background-primary);
$color-background-secondary: xrgb(--color-background-secondary);
$color-background-third: xrgb(--color-background-third);

// FONT-COLORS
$color-font-primary: xrgb(--color-font-primary);
$color-font-secondary: xrgb(--color-font-secondary);
$color-button-font-primary: xrgb(--color-button-front-primary);

// BUTTON-COLORS
$color-button-primary: xrgb(--color-button-primary);
$color-button-secondary: xrgb(--color-button-secondary);

@mixin cssColors(
  $color-background-primary: #E5E5E5,
  $color-background-secondary: #222222,
  $color-background-third: #222222,
  $color-font-primary: #000000,
  $color-font-secondary: #FFFFFF,
  $color-button-font-primary: #FFFFFF,
  $color-button-primary: #222222,
  $color-button-secondary: #222222,
) {
  // BACKGROUNDS
  --color-background-primary: #{hexToRgb($color-background-primary)};
  --color-background-secondary: #{hexToRgb($color-background-secondary)};
  --color-background-third: #{hexToRgb($color-background-third)};

  // FONT-COLORS
  --color-font-primary: #{hexToRgb($color-font-primary)};
  --color-font-secondary: #{hexToRgb($color-font-secondary)};
  --color-button-front-primary: #{hexToRgb($color-button-font-primary)};

  // BUTTON-COLORS
  --color-button-primary: #{hexToRgb($color-button-primary)};
  --color-button-secondary: #{hexToRgb($color-button-secondary)};

}

@mixin baseColors() {
  @include cssColors();
}

@mixin bilanzColors() {
  @include cssColors($color-background-primary: #FFFFFF, $color-background-secondary: #FFFFFF, $color-background-third: #292E32, $color-font-primary: #292E32, $color-font-secondary: #FFFFFF, $color-button-primary: #AA1D21, $color-button-secondary: #570F11, $color-button-font-primary: #FFFFFF,);
}

@mixin blickColors() {
  @include cssColors($color-background-primary: #FFFFFF, $color-background-secondary: #E20000);
}

@mixin handelszeitungColors() {
  @include cssColors($color-background-primary: #FFFFFF, $color-background-secondary: #FFFFFF, $color-background-third: #292E32, $color-font-primary: #292E32, $color-font-secondary: #FFFFFF, $color-button-primary: #AA1D21, $color-button-secondary: #092B4D, $color-button-font-primary: #FFFFFF,);
}

@mixin beobachterColors() {
  @include cssColors($color-background-primary: #FFFFFF, $color-background-secondary: #DF0000, $color-background-third: #f6f6f6, $color-font-primary: #1f1f1f, $color-font-secondary: #1f1f1f, $color-button-primary: #DF0000, $color-button-secondary: #AB0000, $color-button-font-primary: #FFFFFF,);
}

@mixin teleColors() {
  @include cssColors($color-background-primary: #FFFFFF, $color-background-secondary: #cd1013, $color-background-third: #454545, $color-font-primary: #292E32, $color-font-secondary: #FFFFFF, $color-button-primary: #cd1013, $color-button-secondary: #9E0C0F, $color-button-font-primary: #FFFFFF,);
}

@mixin tv4Colors() {
  @include cssColors($color-background-primary: #F6F6F6, $color-background-secondary: #FFFFFF, $color-background-third: #454545, $color-font-primary: #292E32, $color-font-secondary: #FFFFFF, $color-button-primary: #c4151d, $color-button-secondary: #A61218, $color-button-font-primary: #FFFFFF,);
}

@mixin tvstarColors() {
  @include cssColors($color-background-primary: #F6F6F6, $color-background-secondary: #454545, $color-background-third: #454545, $color-font-primary: #292E32, $color-font-secondary: #FFFFFF, $color-button-primary: #cd1013, $color-button-secondary: #9E0C0F, $color-button-font-primary: #FFFFFF,);
}

@mixin tv8Colors() {
  @include cssColors($color-background-primary: #E3E6E9, $color-background-secondary: #FFFFFF, $color-background-third: #454545, $color-font-primary: #292E32, $color-font-secondary: #FFFFFF, $color-button-primary: #0080C5, $color-button-secondary: #006aa4, $color-button-font-primary: #FFFFFF,);
}

@mixin streamingColors() {
  @include cssColors($color-background-primary: #FFFFFF, $color-background-secondary: #00FFA2, $color-background-third: #EBE6E3, $color-font-primary: #000000, $color-font-secondary: #004056, $color-button-primary: #00FFA2, $color-button-secondary: #00FFA2, $color-button-font-primary: #004056,);
}

@mixin pmeColors() {
  @include cssColors($color-background-primary: #F5F5F5, $color-background-secondary: #FFFFFF, $color-background-third: #292E32, $color-font-primary: #292E32, $color-font-secondary: #FFFFFF, $color-button-primary: #E90156, $color-button-secondary: #7F012F, $color-button-font-primary: #FFFFFF,);
}

@mixin landliebeColors() {
  @include cssColors($color-background-primary: #F5F5F5, $color-background-secondary: #FFFFFF, $color-background-third: #FFFFFF, $color-font-primary: #212121, $color-font-secondary: #212121, $color-button-primary: #757B00, $color-button-secondary: #9BA300, $color-button-font-primary: #FFFFFF,);
}

@mixin landliebeColors() {
  @include cssColors($color-background-primary: #F5F5F5, $color-background-secondary: #FFFFFF, $color-background-third: #FFFFFF, $color-font-primary: #212121, $color-font-secondary: #212121, $color-button-primary: #757B00, $color-button-secondary: #9BA300, $color-button-font-primary: #FFFFFF,);
}

@mixin glueckspostColors() {
  @include cssColors($color-background-primary: #F5F5F5, $color-background-secondary: #FFFFFF, $color-background-third: #1A9432, $color-font-primary: #454545, $color-font-secondary: #FFFFFF, $color-button-primary: #1A9432, $color-button-secondary: #147326, $color-button-font-primary: #FFFFFF,);
}

@mixin schweizer-illustrierteColors() {
  @include cssColors($color-background-primary: #FFFFFF, $color-background-secondary: #FFFFFF, $color-background-third: #D4ADAD, $color-font-primary: #000000, $color-font-secondary: #FFFFFF, $color-button-primary: #D51030, $color-button-secondary: #8F0A2A, $color-button-font-primary: #FFFFFF,);
}

@mixin illustreColors() {
  @include cssColors($color-background-primary: #FFFFFF, $color-background-secondary: #FFFFFF, $color-background-third: #F3F3F3, $color-font-primary: #000000, $color-font-secondary: #000000, $color-button-primary: #E3012B, $color-button-secondary: #830018, $color-button-font-primary: #FFFFFF,);
}

@mixin caminadaColors() {
  @include cssColors($color-background-primary: #FFFFFF, $color-background-secondary: #FFFFFF, $color-background-third: #fef2f2, $color-font-primary: #000000, $color-font-secondary: #000000, $color-button-primary: #ff3300, $color-button-secondary: #E40714, $color-button-font-primary: #FFFFFF,);
}

@mixin interviewColors() {
  @include cssColors($color-background-primary: #FFFFFF, $color-background-secondary: #FFFFFF, $color-background-third: #E5E5E5, $color-font-primary: #000000, $color-font-secondary: #000000,);
}
