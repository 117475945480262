.bottom-button {
  background-color: $color-button-primary;
  width: px(48);
  height: px(48);
  border-radius: px(48);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: px(40);
  right: px(60);
}

.bottom-button svg path {
  fill: $color-button-font-primary;
}

.bottom-button:hover {
  background-color: $color-button-secondary;
}
