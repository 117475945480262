@font-face {
  font-family: 'Atkinson Hyperlegible';
  src: url("/fonts/AtkinsonHyperlegible-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Gotham Narrow-Book';
  src: url("/fonts/GothamNarrowBook.ttf") format("truetype");
}

@font-face {
  font-family: 'Gotham Book';
  src: url("/fonts/GothamBook.ttf") format("truetype");
}

@font-face {
  font-family: 'Montserrat';
  src: url("/fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Century Gothic';
  src: url("/fonts/CenturyGothic-Regular.ttf") format("truetype");
}

@font-face {
  font-family: 'Kievit OT Book';
  src: url("/fonts/KievitOTBook-Regular.otf") format("opentype");
}

@font-face {
  font-family: 'PF Bague Sans Pro';
  src: url("/fonts/PFBagueSansPro.ttf") format("truetype");
}

@font-face {
  font-family: 'Atak Light';
  src: url("/fonts/AtakLight.woff2") format("woff2");
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url("/fonts/SourceSansPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: 'Good Off Pro';
  src: url("/fonts/GoodOffcPro-Medium.ttf") format("truetype");
}

$font-family: var(--font-family);
$font-family-secondary: var(--font-family-secondary);

@mixin cssFontConfig(
  $font-family: 'Roboto',
  $font-family-secondary: $font-family,
) {
  --font-family: #{$font-family};
  --font-family-secondary: #{$font-family-secondary};
}

@mixin baseFontConfig() {
  @include cssFontConfig();
}

@mixin bilanzFontConfig() {
  @include cssFontConfig($font-family: 'Gotham Book');
}

@mixin blickFontConfig() {
  @include cssFontConfig($font-family: 'Source Sans Pro', $font-family-secondary: 'Good Off Pro');
}

@mixin handelszeitungFontConfig() {
  @include cssFontConfig($font-family: 'Gotham Book');
}

@mixin beobachterFontConfig() {
  @include cssFontConfig($font-family: 'Gotham Narrow-Book');
}

@mixin teleFontConfig() {
  @include cssFontConfig($font-family: 'Montserrat');
}

@mixin tv4FontConfig() {
  @include cssFontConfig($font-family: 'Montserrat');
}

@mixin tvstarFontConfig() {
  @include cssFontConfig($font-family: 'Montserrat');
}

@mixin tv8FontConfig() {
  @include cssFontConfig($font-family: 'Montserrat');
}

@mixin streamingFontConfig() {
  @include cssFontConfig($font-family: 'Century Gothic');
}

@mixin pmeFontConfig() {
  @include cssFontConfig($font-family: 'Roboto');
}

@mixin landliebeFontConfig() {
  @include cssFontConfig($font-family: 'Kievit OT Book');
}

@mixin glueckspostFontConfig() {
  @include cssFontConfig($font-family: 'Montserrat');
}

@mixin schweizer-illustrierteFontConfig() {
  @include cssFontConfig($font-family: 'PF Bague Sans Pro');
}

@mixin illustreFontConfig() {
  @include cssFontConfig($font-family: 'Montserrat');
}

@mixin caminadaFontConfig() {
  @include cssFontConfig($font-family: 'Atak Light');
}

@mixin interviewFontConfig() {
  @include cssFontConfig($font-family: 'Atak Light');
}
