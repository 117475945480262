$brand-logo: var(--brand-logo);

@mixin cssLogos(
  $brand-logo: '/images/ringier-logo.svg',
) {
  --brand-logo: url(#{$brand-logo});
}

@mixin baseLogos() {
  @include cssLogos();
}

@mixin bilanzLogos() {
  @include cssLogos($brand-logo: '/images/bilanz-logo.svg');
}

@mixin blickLogos() {
  @include cssLogos($brand-logo: '/images/blick-logo.svg');
}

@mixin handelszeitungLogos() {
  @include cssLogos($brand-logo: '/images/hz-logo.svg');
}

@mixin beobachterLogos() {
  @include cssLogos($brand-logo: '/images/beobachter-logo.svg');
}

@mixin teleLogos() {
  @include cssLogos($brand-logo: '/images/tele-logo.svg');
}

@mixin tv4Logos() {
  @include cssLogos($brand-logo: '/images/tvvier-logo.svg');
}

@mixin tvstarLogos() {
  @include cssLogos($brand-logo: '/images/tvstar-logo.svg');
}

@mixin tv8Logos() {
  @include cssLogos($brand-logo: '/images/tv8-logo.svg');
}

@mixin streamingLogos() {
  @include cssLogos($brand-logo: '/images/streaming-logo.svg');
}

@mixin pmeLogos() {
  @include cssLogos($brand-logo: '/images/pme-logo.svg');
}

@mixin landliebeLogos() {
  @include cssLogos($brand-logo: '/images/landliebe-logo.svg');
}

@mixin glueckspostLogos() {
  @include cssLogos($brand-logo: '/images/glueckspost-logo.svg');
}

@mixin schweizer-illustrierteLogos() {
  @include cssLogos($brand-logo: '/images/si-logo.svg');
}

@mixin illustreLogos() {
  @include cssLogos($brand-logo: '/images/lillustre-logo.svg');
}

@mixin caminadaLogos() {
  @include cssLogos($brand-logo: '/images/caminada-logo.svg');
}

@mixin interviewLogos() {
  @include cssLogos($brand-logo: '/images/Interview-Magazin-Logo-RZ.png');
}
