.form-inputs{
  margin-top: px(50);

  label {
    color: $color-font-primary;
    font-weight: normal;
    font-size: px(14);
    line-height: px(17);
  }
  input, select {
    height: 48px;
    box-sizing: content-box;
    margin-top: 2px;
    margin-bottom: 48px;
    padding-left: 12px;
    color: black;
  }

  input, textarea, select {
    border: 2px solid transparent;
    border-bottom: 2px solid #DEE2E6;

    &.valid {
      border-bottom: 2px solid #82C91E;
    }

    &.invalid {
      border: 2px solid #FF0000;
    }

    &:focus {
      outline-width: 0;
    }
  }

  input[type=submit] {
    width: 200px;
    height: 48px;
    background-color: $color-button-primary;
    color: $color-button-font-primary;
    font-weight: bold;
    cursor: pointer;
  }

  .form-error-message {
    color: #FF0000;
    margin-bottom: px(8);
  }
}

.form-input-wrapper {
  display: flex;
}

.text-area-input {
  width: 48%;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .form-input-wrapper {
    flex-direction: column;
  }

  .text-input-wrapper {
    width: 95% !important;
  }

  .text-area-input {
    width: 98%;
  }

  .input-button-wrapper {
    margin-bottom: 10px;
  }
}
